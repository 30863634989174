<template>
<div class="w-full">
     <div class="flex flex-col items-start relative mt-0">
        <div class="w-56">
            <div 
                class="my-2 bg-white p-1 flex border border-secondary rounded-sm cursor-pointer" @click="activeDrop = !activeDrop"
            >
                <div class="flex flex-auto flex-wrap"></div>
                <span 
                    class="p-1 px-2 w-full text-secondary text-left text-sm flex items-center"
                >
                    {{activeTitle}}
                </span>
                <div class="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200">
                    <button class="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up w-4 h-4" :class="{'transY':activeDrop}">
                            <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        
        <div 
            class="absolute shadow top-100 z-40 w-52 lef-0 rounded-sm max-h-select overflow-y-auto hidden" 
            :class="{'activeDrop' : activeDrop }"
        >
            <div class="flex flex-col w-full">
                <div class="w-full  rounded-t border-b bg-white ">
                    <div 
                        v-for="(item, index) in awardsYear" :key="index"
                        class="flex w-full items-center p-2 pl-2 cursor-pointer mb-1 hover:bg-grays-100"
                        @click=handleSelect(item)
                        v-if="activeTitle !== item"
                    >
                        <div class="w-full items-center flex capitalize" >
                            <div class="mx-2 text-sm ">
                                <span v-if="lang == 'id'">{{item}}</span>
                                <span v-else>{{item}}</span>
                            </div>
                        </div>
                    </div>

                    <div 
                        class="flex w-full items-center p-2 pl-2 cursor-pointer mb-1 hover:bg-grays-100"
                        @click=handleSelectAll()
                        v-if="activeTitle !== 'Tampilkan Semua Tahun' && activeTitle !== 'Show All Years'"
                    >
                        <div class="w-full items-center flex capitalize" >
                            <div class="mx-2 text-sm ">
                                <span v-if="lang == 'id'">Tampilkan semua Tahun</span>
                                <span v-else>Show All Years</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-wrap justify-between mt-10">
        
        <div class="shadow-lg mb-10  w-full md:w-47%"  v-for="(item, index) in dtCard" :key="index">

            <div 
                class="h-40 flex-none bg-contain cursorZom bg-no-repeat bg-center rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" :style="{ backgroundImage: `url(${item.image ? item.image : awardimg404 })` }"
                @click="showModal(item.image)"
            >
            </div>

            <div class="bg-grays-100 justify-center  p-4 flex flex-col leading-normal text-left w-full min-h-24">
                <h1 class="text-gray-900 font-bold text-lg text-center">
                    <span v-if="lang == 'id'"> {{item.title1}}</span>
                    <span v-else>{{item.title1_eng}}</span>
                </h1>
                <p class=" text-gray-600 text-sm text-center">
                    <span v-if="lang == 'id'"> {{item.title2}}</span>
                    <span v-else>{{item.title2_eng}}</span>
                </p>
                <p class="mt-2 text-gray-900 font-bold md:text-xs text-center">
                    <span v-if="lang == 'id'"> {{item.description}}</span>
                    <span v-else>{{item.description_eng}}</span>
                </p>
                
            </div>
        </div>
    </div>
       

    <transition name="fade">

        <div class="fixed bglac inset-0 z-50 w-full min-h-full flex place-items-center" v-if="modal"
            @click="onToggle"
        >
            <div
                class="w-full max-w-xl p-3 relative mx-auto my-auto rounded-sm shadow-lg bg-white h-96 bg-contain  bg-center bg-no-repeat"
                :style="{ backgroundImage: `url(${imgActive})` }"
            >
                
            </div>
        </div>

    </transition>

        <div class="h-10 overflow-hidden w-full md:w-5/12 lg:w-1/2 p-0 lg:p-3  mb-20 md:mb-15 lg:mb-10 invisible">
            <div class="bg-grays-200 text-sm rounded-t-sm pl-5 flex items-center h-9">
                
            </div>
            <div class="bg-grays-100 flex justify-between  rounded-b-sm w-full h-full">
                
                
            </div>
        </div>
    
       
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props:{
        dtCard: {
            type: Array
        }
    },
    data() {
        return {
            activeDrop:false,
            activeTitle: 'Filter by Year',
            imgActive:false,
            modal:false,
            awardimg404:'https://dharmagroup.co.id/img/award.7202fe24.svg'
        }
    },

    computed: {
        ...mapState({
            lang: state => state.home.lang ? state.home.lang :'id',
            awardsYear: state => state.about.awardYear ? state.about.awardYear :'',
        })
    },
    
    methods: {
        showModal(image){
            this.imgActive = image
            this.modal = true
        },
        onToggle() {
            this.modal = !this.modal;
            this.imgActive = ''
        },
        async handleSelect(item){
            this.activeTitle = item

            const query = {
                    query: `&search[date]=${item}`
            }
            try {
                await this.$store.dispatch('about/getAwards', query)
                this.activeDrop = false
            } catch (error) {
                console.log(error)
            }


        },
        async handleSelectAll(){
            if(this.lang == 'id'){
                this.activeTitle = 'Tampilkan Semua Tahun'
            }else{
                this.activeTitle = 'Show All Years'
            }
           
            try {
                await this.$store.dispatch('about/getAwards')
                this.activeDrop = false
            } catch (error) {
                console.log(error)
            }
        }
    },

}
</script>

<style scoped>
    .top-100 {top: 100%}
    .bottom-100 {bottom: 100%}
    .max-h-select {
        max-height: 300px;
    }

    .activeDrop{
        display: block;
    }

    .transY{
        transform: rotate(180deg);
    }

    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 300ms ease-out;
    }

    .fade-leave-to {
        opacity: 0;
    }

    .bglac{
        background-color:rgba(0, 0, 0, 0.597);
    }

    .cursorZom{
        cursor: zoom-in;
    }
</style>